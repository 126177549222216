
import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";
import { FormInput } from "@/components/forms";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        FormInput,
        ValidationProvider,
    },
})
export default class ProperyPartInstallationGarageDoor extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: ProperyInstallationGarageDoor;

    localValue: ProperyInstallationGarageDoor = {
        automatic: null,
        has_garage_door: null,
        material: null,
        material_other: null,
    };

    resetValues() {
        this.localValue.automatic = null;
        this.localValue.material = null;
        this.localValue.material_other = null;
        this.handleInput();
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: ProperyInstallationGarageDoor) {
        this.localValue = newValue;
    }
}
